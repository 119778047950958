<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW PARTICULARS (v2)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md4>
            <v-select
              v-model="particulars_mains"
              class="mx-2"
              dense
              outlined
              label="Account"
              :items="particulars_mains_items"
              item-value="id"
              item-text="particulars_main"
              @change="selected_particulars_mains"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4 v-if="particulars_main_title_items.length>0">
            <v-select
              v-model="particulars_main_title"
              class="mx-2"
              dense
              outlined
              label="Account Title"
              :items="particulars_main_title_items"
              item-value="id"
              item-text="particulars_main_title"
              @change="selected_particulars_main_title"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4 v-if="particulars_data_items.length>0">
            <v-select
              v-model="particulars_data"
              class="mx-2"
              dense
              outlined
              label="Account Title Data"
              :items="particulars_data_items"
              item-value="id"
              item-text="particulars_data"
              @change="selected_particulars_data"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="account_code"
              label="Account Code"
              dense
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
              style="font-weight: bold"
              type="number"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="particulars"
              label="Particulars"
              dense
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="save_request"
              v-if="!saving_data"
            >
              Save Particulars
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-data-table
        dense
        disable-sort
        :headers="headers"
        disable-pagination
        hide-default-footer
        :items="particulars_mains_items"
        item-key="id"
        group-by="id"
      >
        <template v-slot:group.header="{items, isOpen, toggle}">
          <th colspan="2" style="font-size: x-large">
            <v-icon @click="toggle"
            >{{ isOpen ?icons.mdiMenuDown : icons.mdiMenuUp }}
            </v-icon>
            <strong>{{ '('+items[0].code+') - '+ items[0].particulars_main}}</strong>
          </th>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td></td>
            <td>
              <v-data-table
                disable-pagination
                dense
                disable-sort
                hide-default-footer
                :items="item.particulars_main_titles"
                item-key="id"
                group-by="id"
              >
                <template v-slot:group.header="{items, isOpen, toggle}">
            <th colspan="2" style="font-size: medium">
              <v-icon @click="toggle"
              >{{ isOpen ?icons.mdiMenuDown : icons.mdiMenuUp }}
              </v-icon>
              <strong style="color: cadetblue">{{ '('+items[0].code+') - '+
                items[0].particulars_main_title}}</strong>
            </th>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td></td>
            <td>
              <v-data-table
                disable-pagination
                dense
                disable-sort
                hide-default-footer
                :items="item.particulars_main_titles_data"
              >
                <template v-slot:item="{ item }">
          <tr>
            <td @click="selected_particulars(item)"><strong style="color: hotpink; cursor: pointer">{{
              '('+item.code+') - '+
              item.particulars_data}}</strong></td>
            <td>
              <v-data-table
                disable-pagination
                dense
                disable-sort
                hide-default-footer
                :items="item.particulars_main_titles_data_details"
              >
                <template v-slot:item="{ item }">
          <tr>
            <td><p style="color: orangered">{{ '('+item.code+') - '+
              item.particulars_data_details}}</p></td>
          </tr>
        </template>
      </v-data-table>
      </td>
      </tr>
</template>
</v-data-table>
</td>
</tr>
</template>
</v-data-table>
</td>
</tr>
</template>
</v-data-table>
</v-card>
<v-dialog v-model="is_assigned" persistent max-width="50%">
  <v-card>
    <v-toolbar dense dark color="primary">
      <v-toolbar-title
      ><h5 class="font-weight-light">ASSIGN {{this.selected_data.particulars_data}} TO
        COMPANIES</h5>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form2" class="multi-col-validation mt-4">
        <v-row>
          <v-col md="12" cols="12">
            <v-select
              class="mx-2"
              v-model="category_id"
              dense
              label="Company"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
      <v-col cols="12" v-show="alert">
        <v-alert color="warning" text class="mb-0">
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{ alert_message }}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>
    </v-card-text>
  </v-card>
  <v-btn color="primary" @click="assigned_details"> Save Changes</v-btn>
  <v-btn color="error" @click="is_assigned = false"> Close</v-btn>
</v-dialog>
<snack-bar-dialog
  :snackbar_flag="this.snackbar"
  :color="this.snackbar_color"
  :snackbar_text="this.snackbar_text"
/>
</div>
</template>

<script>
  import {
    mdiCheck, mdiClose,
    mdiMenuDown, mdiMenuUp
    , mdiAccountOff
    , mdiAccountCheck
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      is_assigned: false,
      saving_data: false,
      alert: false,
      alert_message: '',

      category_id: '',
      category_items: [],

      particulars_mains: '',
      particulars_mains_items: [],
      particulars_main_title: '',
      particulars_main_title_items: [],
      particulars_data: '',
      particulars_data_items: [],

      selected_data: {},
      account_code: '',
      particulars: '',
      headers: [
        {text: 'ACCOUNT', value: 'date_of_deposit', sortable: false},
        {text: 'TITLE', value: 'last_name', sortable: false},
      ],

      is_deleting: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiMenuDown,
          mdiClose,
          mdiCheck,
          mdiMenuUp,
          mdiAccountOff
          , mdiAccountCheck
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('particulars_mains', ['list_of_particulars_main', 'create_particulars_version2', 'assigned_particulars_to_companies']),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      initialize_data() {
        this.list_of_particulars_main()
          .then(response => {
            this.particulars_mains_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.initialize_new_particulars()
          .then(response => {
            this.category_items = response.data
            this.category_items.splice(0, 1)
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_particulars(value) {
        this.selected_data = value
        this.is_assigned = true
      },
      assigned_details() {
        const data = new FormData()
        data.append('particulars', this.selected_data.particulars_data)
        data.append('category', this.category_items[this.category_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.category_id)].category)
        data.append('particulars_id', this.selected_data.id)
        data.append('category_id', this.category_id)
        this.assigned_particulars_to_companies(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving = false
          })
      },
      selected_particulars_mains() {
        this.is_deleting = false
        this.particulars_main_title = ''
        this.particulars_main_title_items = []
        this.particulars_data = ''
        this.particulars_data_items = []
        this.account_code = ''
        var index = this.particulars_mains_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.particulars_mains)
        if (index != -1) {
          this.particulars_main_title_items = this.particulars_mains_items[index].particulars_main_titles
          this.account_code = this.particulars_mains_items[index].code + ''
        }
      },
      selected_particulars_main_title() {
        this.is_deleting = false
        this.particulars_data = ''
        this.particulars_data_items = []
        this.account_code = ''
        var index = this.particulars_main_title_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.particulars_main_title)
        if (index != -1) {
          this.particulars_data_items = this.particulars_main_title_items[index].particulars_main_titles_data
          this.account_code = this.particulars_main_title_items[index].code + ''
        }
      },
      selected_particulars_data() {
        this.is_deleting = false
        this.account_code = ''
        var index = this.particulars_data_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.particulars_data)
        if (index != -1) {
          this.account_code = this.particulars_data_items[index].code + ''
        }
      },
      change_status(value, status) {
        this.is_deleting = true
        const data = new FormData()
        data.append('id', value)
        data.append('status', status)
        this.change_status_of_particulars(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            } else {
              this.selected_particulars_mains()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving = false
          })
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('code', this.account_code);
          data.append('particulars', this.particulars);

          data.append('particulars_mains_id', this.particulars_mains);
          data.append('particulars_main_title_id', this.particulars_main_title);
          data.append('particulars_data_id', this.particulars_data);
          this.create_particulars_version2(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              // Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
